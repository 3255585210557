<template>
  <button
    class="admin-btn h-9 m-2 rounded-lg bg-gray-400 p-4 flex flex-row items-center justify-center text-base shadow-md hover:bg-[#2197a7]"
    @click="visitJob"
    :disabled="isDisabled || !jobUrl"
  >
    <ExternalLinkIcon class="h-5 w-5 mr-2" aria-hidden="true" />
    <span>{{ $t("job-clustering.visit-job") }}</span>
  </button>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { ExternalLinkIcon } from "@heroicons/vue/outline"; 

export default {
  name: "VisitJobButton",
  components: {
    ExternalLinkIcon,
  },
  data() {
    return {
      jobUrl: "",  
      currentJobId: null, 
    };
  },
  computed: {
    ...mapGetters(["backendUrl", "authorizationHeader"]),
  },
  methods: {
    // Fetches the job URL from the backend
    async fetchJobUrl(jobId) {
      let headers = this.authorizationHeader;
      try {
        const response = await axios.get(this.backendUrl + "/job/visit-job/" + "?jobId=" + jobId, { headers });
        this.jobUrl = response.data.url;  
        this.currentJobId = jobId;  
      } catch (error) {
        console.error("Error fetching job URL:", error);
      }
    },

    async visitJob() {
      const jobId = this.$route.params.jobId;

      if (this.currentJobId !== jobId) {
        await this.fetchJobUrl(jobId); 
      }

      if (this.jobUrl) {
        window.open(this.jobUrl, "_blank"); // Open the URL in a new tab
      } else {
        console.error("Job URL not found");
      }
    },
  },

  // Fetch the URL when the component is mounted
  mounted() {
    const jobId = this.$route.params.jobId;
    this.fetchJobUrl(jobId); 
  },
};
</script>
